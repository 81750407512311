import React, { useState, useEffect } from "react";
import styled from "styled-components";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import db from "../firebase";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import Addmaterial from "./Addmaterial";
import { SetModal } from "../features/modalSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { selectType } from "../features/modalSlice";

import Modal from "./Modal";
import Table from "../ui/Table/Table";

const Sites = () => {
  const modalType = useSelector(selectType);
  const [materials, setMaterials] = useState([]);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");

  const dispatch = useDispatch();
  const [editForm, setEdit] = useState("");
  const [formId, setFormId] = useState("");

  const siteRef = query(
    collection(db, "MaterialData"),
    orderBy("createdAt", "desc")
  );

  useEffect(() => {
    if (search) {
      const res = materials.filter((item) =>
        item.materialName?.toLowerCase().includes(search.toLowerCase())
      );
      setData(res);
    } else {
      setData(materials);
    }
  }, [search, materials]);

  const getData = async () => {
    try {
      onSnapshot(siteRef, (querySnapshot) => {
        setMaterials(
          querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        );
      });
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const handleEdit = (formId) => {
    switch (editForm) {
      case "addmaterial":
        setEdit("");
        setFormId("");
        break;
      case "":
        setFormId(formId);
        setEdit("addmaterial");
        break;
      default:
        setEdit("");
        setFormId("");
    }
  };

  const handlerDelete = async (id) => {
    dispatch(
      SetModal({
        type: "material",
        dbName: "MaterialData",
        id: id,
      })
    );
  };

  const columns = [
    {
      id: "materialImage",
      label: "Photo of Material",
      Cell: (id) => (
        <BgImg>
          <img src={id} alt="img1" />
        </BgImg>
      ),
    },
    { id: "materialName", label: "Material Name" },
    { id: "materialUnit", label: "Unit of Measurement" },
    {
      id: "id",
      label: "Action",
      Cell: (id) => (
        <ButtonWrapper>
          <button onClick={() => handleEdit(id)}>
            <EditIcon fontSize="small" />
          </button>
          <button
            style={{ color: "#F44336" }}
            onClick={() => handlerDelete(id)}
          >
            <DeleteOutlinedIcon fontSize="small" />
          </button>
        </ButtonWrapper>
      ),
    },
  ];

  return (
    <>
      <Container>
        <SearchContainer>
          <div>
            <h2>Material Data Table</h2>
            <p>Overview of all Materials</p>
          </div>
          <div>
            <Fields>
              <label htmlFor="">Search</label>
              <input
                type="text"
                placeholder="Search by material name..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Fields>
          </div>
        </SearchContainer>
        <Table data={data} columns={columns} />
      </Container>
      <Addmaterial editForm={editForm} handlEdit={handleEdit} formId={formId} />
      {modalType && <Modal />}
    </>
  );
};

const Container = styled.div`
  padding: 20px 10px;
  background-color: #fff;
  box-shadow: 0 0 5px 0 rgb(0 0 0 / 10%);
  overflow: hidden;
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  > div {
    &:last-child {
      display: flex;
      align-items: center;
    }
  }
`;

const Fields = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  position: relative;
  margin-right: 15px;
  input {
    width: 100%;
    padding: 10px;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.3);
    &::placeholder {
      color: #000;
    }
  }
  input,
  select {
    height: 35px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 5px;
  }
  label {
    position: absolute;
    background-color: #fff;
    font-size: 13px;
    left: 10px;
    top: -12px;
    padding: 3px;
    color: #666666;
  }
  select,
  option {
    padding-right: 50px;
  }
`;

const BgImg = styled.div`
  position: relative;
  z-index: 1;
  width: 72px;
  height: 72px;
  overflow: hidden;
  border-radius: 4px;
  margin: 0 auto;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
    rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
    rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: inline-block;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    font-size: 20px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
`;

export default Sites;
